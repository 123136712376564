/* eslint-disable no-unused-expressions */
import { required } from 'vuelidate/lib/validators';

const workHoursValidationMixin = {
	validations: {
		cmsWorkHours: {
			$each: {
				cwhDay: {
					required,
				},
				cwhHours: {
					required,
				},
			},
		},
	},
	methods: {
		// validation methods for this page
		cwhDayErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsWorkHours.$each[index].cwhDay;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen gün giriniz.');
			return errors;
		},
		cwhHoursErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsWorkHours.$each[index].cwhHours;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen saat aralığı giriniz.');
			return errors;
		},
	},
};

export default workHoursValidationMixin;
